import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'react-intl'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Title from '../components/Title'
import Text from '../components/Text'
import Spacer from '../components/Spacer'
import title3Styles from '../styles/title3'

const List = styled.ul`
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    ${title3Styles}
    text-decoration: none;
    padding: 0.5rem 0;
    display: block;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }
`

const CareerPage = () => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'career.title' })} />
      <Content intro>
        <Title top={intl.formatMessage({ id: 'career.title' })}>
          <FormattedMessage id="career.headline" />
        </Title>
        <Text align="center">
          <FormattedHTMLMessage id="career.content" />
        </Text>
        <Spacer size="small" />

        <List>
          <li>
            <a
              href="/Gasser_Partner_Stelleninserat_Rechtsanwalt.pdf"
              target="_blank"
            >
              {intl.locale === 'de' ? (
                <>Rechtsanwältin / Rechtsanwalt (PDF)</>
              ) : (
                <>Lawyer (PDF only in German)</>
              )}
            </a>
          </li>

          <li>
            <a
              href="/Gasser_Partner_Stelleninserat_Anwaerter.pdf"
              target="_blank"
            >
              {intl.locale === 'de' ? (
                <> Rechtsanwaltsanwärterin / Rechtsanwaltsanwärter (PDF)</>
              ) : (
                <>Associate (PDF only in German)</>
              )}
            </a>
          </li>

          <li>
            <a
              href="/Gasser_Partner_Stelleninserat_Assistent.pdf"
              target="_blank"
            >
              {intl.locale === 'de' ? (
                <> Rechtsanwaltsassistentin / Rechtsanwaltsassistent (PDF)</>
              ) : (
                <>Assistant (PDF only in German)</>
              )}
            </a>
          </li>

          <li>
            <a href="/Stellenanzeige_Client_Advisor_v2_PDF.pdf" target="_blank">
              Client Advisor (m/w/d)
            </a>
          </li>

          <li>
            <a
              href="/Gasser_Partner_Stelleninserat_Trust_Officer.pdf"
              target="_blank"
            >
              Trust Officer (m/w/d)
            </a>
          </li>
        </List>

        <Spacer size="small" />
      </Content>
    </Layout>
  )
}

export default CareerPage

export const i18n = {
  en: '/career',
  de: '/karriere',
}
